/* eslint-disable react/prop-types */
import React from 'react';
import {
  ShareLink, SidebarSection, SidebarSectionHeader, Row, Body
} from '@paddl/storybook';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { useResource } from '@paddl/utils-react';
import { urlStringForPathKey } from '@paddl/utils-js';
import {
  ProfileStrength,
  ProfileViews,
  SideContentLinks
} from '../../components';
import { useMyProfile } from './MyProfileContext';
import { ProfileSuggestions } from '../../components/ProfileSuggestions/ProfileSuggestions';

export const MyProfileSideContent = () => {
  const { data: statsData, isLoading: isStatsDataLoading  } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/stats`);

  const { profile, isLoading: isProfileLoading } = useMyProfile();

  const { data: profileViews } = useResource(`${process.env.API_PREFIX_PROFILE_CLICK_COUNT}/view/${profile?.profileId}`, isProfileLoading);
  const { total_views, trend_percentage, trend_direction } = profileViews || { total_views: 0, trend_percentage: 0, trend_direction: 'sideways' };

  if (isStatsDataLoading || isProfileLoading) {
    return null;
  }
  const { activitiesCount } = statsData.stats;

  const shareLink = urlStringForPathKey('publicProfileBySlug', {
    slug: profile.urlSlug || profile.profileId
  });

  return (<>

            <SidebarSection>
              <SidebarSectionHeader
                icon={<VisibilitySharpIcon />}
                title="Profile Views"
              />
                <Row style={{ gap: '32px' }}>
                  <Body>Share your Profile with Employers and on social to grow your exposure.</Body>
                  <ProfileViews
                    totalViews={total_views}
                    trendPercentage={trend_percentage}
                    trendDirection={trend_direction}
                  />
                </Row>
              <ShareLink url={shareLink} />
              <SideContentLinks shareLink={shareLink} />
            </SidebarSection>
              <ProfileStrength
                activityCount={activitiesCount}
                profile={profile}
              />
          <ProfileSuggestions
            profileIdOrSlug={`${profile.profileId}`}
          />
          </>);
};
