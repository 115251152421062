import {
  ProfileHero,
  useWidth
} from '@paddl/storybook';
import {
  Metadata, useResource, SummaryTabPanel, TimelineTabPanel,
  useSubmitData
} from '@paddl/utils-react';
import t from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  ShareProfileModal,
  TabContent
} from '../../components';

export const NEON_ProfilePageContent = ({
  profileIdOrSlug
}) => {
  const width = useWidth();
  const { data: statsData, isLoading: isStatsLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/public/profile/${profileIdOrSlug}/stats`);
  const { data: profileData, isLoading: isProfileLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/public/profile/${profileIdOrSlug}`);
  const { data: timelineData, isLoading: isTimelineLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/public/profile/${profileIdOrSlug}/timeline`);
  const { submit } = useSubmitData();
  const {
    profileId, fullName, title, avatarUrl, bannerUrl, location
  } = profileData?.profile || {};

  useEffect(() => {
    if (!profileId) return;
    submit(`${process.env.API_PREFIX_PROFILE_CLICK_COUNT}/view/${profileId}`, '*');
  }, [profileId, submit]);

  const { totalXp, activitiesCount, skillsCount } = statsData?.stats || {};

  const [doneInitialLoad, setDoneInitialLoad] = useState(false);

  useEffect(() => {
    if (!(isStatsLoading || isProfileLoading)) {
      setDoneInitialLoad(true);
    }
  }, [isProfileLoading, isStatsLoading]);

  if (!doneInitialLoad) {
    return (<Metadata title="Profile | Paddl" />);
  }

  return (<>
      <Metadata title="Profile | Paddl" />
        <ProfileHero
          profileDetails={{
            fullName,
            profileTitle: title,
            avatarUrl,
            location
          }}
          profileStats={{
            totalXp,
            totalActivities: activitiesCount,
            totalSkills: skillsCount
          }}
          backgroundImage={bannerUrl}
          width={width}
        />
      <TabContent
        summary={
            (isProfileLoading || isStatsLoading) ? null
              : <SummaryTabPanel
                  profile={profileData.profile}
                  activitiesCount={activitiesCount}
              />
        }
        activities={
            isTimelineLoading ?  null
              : <TimelineTabPanel
                  timeline={timelineData.timeline}
              />

        }
      />
      <ShareProfileModal
        avatarUrl={avatarUrl}
        profilePath={profileIdOrSlug}
      />
          </>
  );
};

NEON_ProfilePageContent.propTypes = {
  profileIdOrSlug: t.string.isRequired
};
