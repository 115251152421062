import {
  ProfileHero,
  useWidth,
  SnackbarFeedback_V2
} from '@paddl/storybook';
import {
  Metadata,
  useResource
} from '@paddl/utils-react';
import {
  shape
} from 'prop-types';
import React, { useContext } from 'react';
import {
  EditProfileSummary,
  NEON_EditProfilePictureModal,
  ShareProfileModal
} from '../../components';
import { NEON_EditProfileBannerModal } from '../../components/EditProfileBannerModal/NEON_EditProfileBannerModal';
import { NEON_EditProfileInformationModal } from '../../components/EditProfileInformationModal/NEON_EditProfileInformationModal';
import { NEON_EditProfileLinksModal } from '../../components/EditProfileLinksModal/NEON_EditProfileLinksModal';
import { TabContent } from '../../components/TabContent';
import { ModalContext } from '../Profile/ModalContext';
import { useMyProfile } from './MyProfileContext';
import { MyProfileSummary } from './MyProfileSummary';
import { MyTimeline } from './MyTimeline';

const ModalManager = ({
  profile
}) => {
  return <>
    <NEON_EditProfileInformationModal />
    <NEON_EditProfileLinksModal />
    <NEON_EditProfilePictureModal />
    <NEON_EditProfileBannerModal />
    <EditProfileSummary />
    {
      profile && <ShareProfileModal
        avatarUrl={profile.avatarUrl}
        profilePath={profile.urlSlug || profile.profileId}
      />
    }

         </>;
};

ModalManager.defaultProps = {
  profile: null
};

ModalManager.propTypes = {
  profile: shape({})
};

export const MyProfilePageContent = () => {
  const width = useWidth();
  const { data: statsData, isLoading: isStatsLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/stats`);

  const { setOpenModal } = useContext(ModalContext);

  const { profile, isLoading: isProfileLoading } = useMyProfile();

  const isLoading = (isStatsLoading || isProfileLoading);
  const {
    fullName, title, avatarUrl, location
  } = profile;
  const { totalXp, activitiesCount, skillsCount } = statsData?.stats || {};

  return (<>
      <Metadata title="Profile | Paddl" />
        {!isLoading && <><ProfileHero
          onClick={() => setOpenModal('edit-profile-picture')}
          profileDetails={{
            fullName,
            profileTitle: title,
            avatarUrl,
            location
          }}
          profileStats={{
            totalXp,
            totalActivities: activitiesCount,
            totalSkills: skillsCount
          }}
          backgroundImage={profile?.bannerUrl || ''}
          width={width}
        />

      <TabContent
        summary={
          <MyProfileSummary />
        }
        activities={<MyTimeline />}
      />
                       </>}
      <ModalManager profile={profile} />
      <SnackbarFeedback_V2 />
          </>
  );
};
