import { ProfileSettings } from '../pages';
import { MyProfile } from '../pages/MyProfile/MyProfile';
import { NEON_Profile } from '../pages/Profile/NEON_Profile';

export const routerConfig = [
  {
    path: '/xp/:profileId',
    component: NEON_Profile
  },
  {
    path: '/profiles/my-profile',
    component: MyProfile
  },
  {
    path: '/settings',
    component: ProfileSettings
  }
];
